<template lang="">
  <div>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-4 md:grid-cols-2">
          <TitlePlus  :title="$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.headline')" :hide-plus="true" />
          <div class="md:flex md:justify-end">
            <DateRangePicker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>
      <div
        class="flex flex-col gap-2 my-1 md:items-center md:flex-row px-5"
      ></div>

      <FSTable
        :fst-id="`marketplaceBuyerOrderIndex`"
        :searchEnabled="false"
        :headers="tableHeaders"
        :endpoint="getEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            text-fallback-always
          >
            <FSTableRowItem :text="item.id" :truncate="-5" />

            <FSTableRowItem :text="item.created_at" :date="true" />
            <FSTableRowItem :text="item.number_of_vehicles_to_buy" />
            <FSTableRowItem
              :text="
                `${item.marketplace_fleet.fleet.country.currency_symbol} ${item.order_amount}`
              "
            />

            <FSTableRowItem>
              <XStatus
                :text="item.order_status"
                :variant="getItemVariant(item.order_status)"
                profile="payment"
              />
            </FSTableRowItem>
            <FSTableRowItem>
              <MoreActions
                :key="`more-actions-${itemIndex}`"
                :data="item"
                @delete="$store.dispatch('fsTable/fetchData')"
              />
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </div>
</template>
<script>
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import XStatus from '@/components/badge/XStatus'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import MoreActions from '@/views/marketplace/shared/MoreActions.vue'
export default {
  name: 'ViewBuyerOrders',
  components: {
    ContentSection,
    TitlePlus,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    XStatus,
    DateRangePicker,
    MoreActions,
  },
  data() {
    return {
      path: '',
      tableHeaders: [
        {  text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.orderId'), width: '10%', sort: 'id' },
        { text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.transactionTime'), width: '20%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.noOfVehicle'), width: '15%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.amount'), width: '15%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.status'), width: '15%', sort: 'null' },
        { text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.orders.table.columns.action'), width: '10%', sort: 'null' },
      ],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    getEndpoint() {
      return MarketplaceConfig.api.marketplaceBuyerOrder(this.id)
    },
  },
  async created() {
    this.path = this.$route.path
  },
  methods: {
    onApplyFilterDateRange,
    getItemVariant(data) {
      if (data === 'paid') return 'green'
      if (data === 'failed') return 'red'
      if (data === 'unpaid') return 'gray'
      return 'orange'
    },
  },
}
</script>
<style lang=""></style>
